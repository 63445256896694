import { sepolia } from 'viem/chains'
import { createConfig, http, WagmiProvider } from 'wagmi'

const alchemySepoliaRpcUrl = process.env.NEXT_PUBLIC_ALCHEMY_SEPOLIA_RPC_URL

const WagmiProviderWrapper = ({ children }: { children: React.ReactNode }) => {
  const wagmiConfig = createConfig({
    chains: [sepolia],
    transports: {
      [sepolia.id]: http(alchemySepoliaRpcUrl),
    },
  })

  return <WagmiProvider config={wagmiConfig}>{children}</WagmiProvider>
}

export { WagmiProviderWrapper }
